
























































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { AppProvider, appProvider } from '@/app-providers'

@Observer
@Component({
  components: {}
})
export default class Home extends Vue {
  @Provide() providers: AppProvider = appProvider

  wallet = walletStore
  // destroyed() {
  // this.vm.destroy()
  // }
}
